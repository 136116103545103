import '/assets/styles/partials/eventbox/types/regular.scss';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import { useInView } from 'react-intersection-observer'

// Functions
import formatDate from '@wearetla/tla-essentials-tools/functions/format-date';
import createCalendarEventBtnParams from '/functions/create-calendar-event-btn-params';

// Context
import { useGlobalEvents } from '@wearetla/tla-essentials-tools/utilities/global-events';

// Partials
import Link from '@wearetla/tla-essentials-tools/partials/link';
import Img from '@wearetla/tla-essentials-tools/partials/img';
import Icon from '@wearetla/tla-essentials-tools/partials/icon';
import Placeholder from '/views/partials/placeholder';
import Btn from '/views/partials/btn';
import Badge from '/views/partials/badge';

export const Eventbox = ({ event: propEvent, analytics, className }) => {
	const { triggerEvent } = useGlobalEvents();
	const [observerRef, inView] = useInView({
		threshold: 0,
		rootMargin: '5%',
		triggerOnce: true,
		disabled: !analytics,
	});

	const event = useMemo(() => (propEvent ? {
		...propEvent,
		dateTimeString: propEvent.full_date ? formatDate(propEvent.full_date, 'dd.MM.y - HH:mm') : null,
	} : propEvent), [propEvent])

	const containerClasses = classNames('eventbox', 'type-regular', className);

	useEffect(() => {
		if(analytics && inView && propEvent) {
			triggerEvent('listItemView', {
				listId: analytics.listId,
				listName: analytics.listName,
				item: propEvent,
			})
		}
	}, [inView, propEvent, analytics])
	
	return (
		<>
			{event ?
				<Link
					href="eventDetail"
					params={{id: event.event_id, slug: event.slug, calendarId: event.calendar_id }}
					className={containerClasses}>
					<div className="eventbox-imagewrap">
						<div className="imagewrap-labels">
							{(!!event.promotion_message?.length) &&
								<Badge
									variant="warning">
									{event.promotion_message}
								</Badge>
							}
							<Badge
								variant="red-darker">
								{event.type_name}
							</Badge>
						</div>
						<Img
							cover
							className="eventbox-image"
							src={event.image} />
					</div>
					<div
						ref={observerRef}
						className="eventbox-text">
						<strong className="eventbox-title">
							{event.title}
						</strong>

						<div className="eventbox-infofields">
							{event.event_location &&
								<div className="infofields-item">
									<Icon name="map-pin" /> {event.event_location}
								</div>
							}

							<div className="infofields-item">
								<Icon name="time" /> {event.dateTimeString}
							</div>
						</div>

						<div className="eventbox-controls">
							{event.calendar_id &&
								<Btn
									{...createCalendarEventBtnParams(event)}
									className="eventbox-buybtn primary">
									BİLET AL
								</Btn>
							}
							<Btn
								hollowLink
								href="eventDetail"
								params={{id: event.id, slug: event.slug, calendarId: event.calendar?.id }}
								className="eventbox-detailsbtn nude">Detayları Gör</Btn>
						</div>

					</div>
				</Link>
				:
				<div className={containerClasses}>
					<div className="eventbox-imagewrap">
						<Placeholder className="eventbox-image" />
					</div>
					<div className="eventbox-text">
						<Placeholder className="eventbox-title" />

						<div className="eventbox-infofields">
							<Placeholder className="infofields-item" />
							<Placeholder className="infofields-item" />
						</div>

						<div className="eventbox-controls">
							<Placeholder className="eventbox-buybtn" />
							<Placeholder className="eventbox-detailsbtn" />
						</div>
					</div>
				</div>
			}
		</>
	)
}

Eventbox.propTypes = {
	event: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([false])]),
}

export default Eventbox;