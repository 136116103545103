export const createCalendarEventBtnParams = (event, option) => {
	return event.buy_btn_link?.length ? {
		hollowLink: true,
		// tag: 'link',
		href: event.buy_btn_link,
		raw: true,
		target: '_blank',
		// rel: 'noopener noreferrer',
	}
		:
	{
		hollowLink: true,
		href: 'checkout',
		params: {
			id: event.event_id,
			calendarId: event.calendar_id,
			...(option ? { optionId: option.id } : {})
		},
	};
}

export default createCalendarEventBtnParams;